import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';
import Home from './pages/Home.jsx';
import About from './pages/About.jsx';
import Projects from './pages/Projects.jsx';
import ProjectDetail from './pages/ProjectDetail.jsx';
import Error from './pages/Error.jsx';

import Layout from './components/Layout.jsx';

import { LngProvider, ThemeProvider } from './outils/Context.jsx';

function App() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                path='/'
                element={<Layout />}
            >
                <Route
                    index
                    element={<Home />}
                />
                <Route
                    path='projects'
                    element={<Projects />}
                />
                <Route
                    path='projects/:id'
                    element={<ProjectDetail />}
                />
                <Route
                    path='about'
                    element={<About />}
                />
                <Route
                    path='*'
                    element={<Error />}
                />
            </Route>
        )
    );
    return (
        <LngProvider>
            <ThemeProvider>
                <RouterProvider router={router} />
            </ThemeProvider>
        </LngProvider>
    );
}

export default App;
