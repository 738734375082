import * as THREE from 'three';
import { useRef } from 'react';
import { PointMaterial } from '@react-three/drei';
import { useControls } from 'leva';
import { useFrame } from '@react-three/fiber';

import { colorUp, colorDown } from '../../styles/main.module.scss';

// Colors for the particles
const colorThreeUp = new THREE.Color(colorUp).convertSRGBToLinear(); // rose
const colorThreeDown = new THREE.Color(colorDown).convertSRGBToLinear(); // beige

//Global variables
let particlesCount = 5000;
let points = null;

const particlesColorLerp = (alpha) => {
    const mixedColor = colorThreeDown.clone();
    if (alpha < 0) {
        alpha = 0;
    }
    mixedColor.lerpColors(colorThreeDown, colorThreeUp, alpha);

    return mixedColor;
};

const generateParticles = (dist) => {
    const { sizeAttenuation, transparent, depthWrite, vertexColors, toneMapped, fog, alphaTest } =
        useControls('Particles', {
            sizeAttenuation: true,
            transparent: true,
            depthWrite: false,
            vertexColors: true,
            toneMapped: false,
            fog: true,
            alphaTest: { value: 0.001, min: 0, max: 1, step: 0.01 },
            // depthTest: true,
        });

    // const material = useRef(null);
    points = useRef(null);

    /**
     * Destroy old galaxy
     */

    if (points.current !== null) {
        points.current.geometry.dispose();
        points.current.material.dispose();
    }

    // points.current.visible = true;

    // geometry
    let positions = new Float32Array(particlesCount * 3);
    let colors = new Float32Array(particlesCount * 3);

    // positions & colors
    // positions = useMemo(() => {
    for (let i = 0; i < particlesCount; i++) {
        positions[i * 3 + 0] = (Math.random() - 0.5) * 18;
        positions[i * 3 + 1] = dist * 4 * (Math.random() - 0.5);
        positions[i * 3 + 2] = (Math.random() - 0.5) * 10;

        const lerpAlpha = (positions[i * 3 + 1] + 1) / 5;

        colors[i * 3] = particlesColorLerp(lerpAlpha).r;
        colors[i * 3 + 1] = particlesColorLerp(lerpAlpha).g;
        colors[i * 3 + 2] = particlesColorLerp(lerpAlpha).b;
    }
    //     return positions;
    // }, []);

    // points.current.visible = true;
    // animation
    //cursor variables
    const cursor = {};
    cursor.x = 0;
    cursor.y = 0;

    //window sizes
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    window.addEventListener('mousemove', (event) => {
        cursor.x = event.clientX / sizes.width - 0.5;
        cursor.y = event.clientY / sizes.height - 0.5;
    });

    useFrame((state) => {
        const t = state.clock.elapsedTime;

        positions.forEach((p, i) => [
            (i + 1) % 3
                ? null //(positions[i] += Math.sin(10 * i + t) / 5000)
                : (positions[i] +=
                      Math.cos(positions[i - 2] + t * 2) / 50 +
                      Math.sin(positions[i - 2] + t * 0.38) / 300),
        ]);
        points.current.geometry.attributes.position.needsUpdate = true;
    });
    return (
        <points ref={points}>
            <bufferGeometry>
                <bufferAttribute
                    attach='attributes-position'
                    count={particlesCount}
                    array={positions}
                    itemSize={3}
                />
                <bufferAttribute
                    attach='attributes-color'
                    count={particlesCount}
                    args={[colors, 3]}
                />
            </bufferGeometry>
            <PointMaterial
                size={0.2}
                sizeAttenuation={sizeAttenuation}
                transparent={transparent}
                depthWrite={depthWrite}
                vertexColors={vertexColors}
                toneMapped={toneMapped}
                fog={fog}
                // depthTest={depthTest}
                alphaTest={alphaTest}
            />
        </points>
    );
};

const Particles = (props) => {
    const toBe = generateParticles(props.dist);
    return <group position-y={props.dist}>{toBe}</group>;
};

export default Particles;
