import React from 'react';

import leftArrow from '../../public/images/leftArrow.png';
import rightArrow from '../../public/images/rightArrow.png';

const SlideShow = (props) => {
    const [currentImage, setCurrentImage] = React.useState(0);
    const slidesLenght = props.imagesSrc ? props.imagesSrc.length : 0;

    const nextSlide = () => {
        setCurrentImage(currentImage === slidesLenght - 1 ? 0 : currentImage + 1);
    };

    const prevSlide = () => {
        setCurrentImage(currentImage === 0 ? slidesLenght - 1 : currentImage - 1);
    };

    return (
        <div className='slidecontainer'>
            <figure
                className='slidecontainer__element'
                style={{
                    transform: `translate3d(${-currentImage * 100}%, 0, 0)`,
                }}
            >
                {props.imagesSrc &&
                    props.imagesSrc.map((url, index) => (
                        <img
                            key={index}
                            src={`${url}`}
                            alt={`visual site ${index}`}
                        />
                    ))}
            </figure>
            <img
                className={
                    slidesLenght > 1 ? `slidecontainer__leftArrow` : `slidecontainer__noArrow`
                }
                src={leftArrow}
                alt='left arrow icon'
                onClick={prevSlide}
            />
            <img
                className={
                    slidesLenght > 1 ? `slidecontainer__rightArrow` : `slidecontainer__noArrow`
                }
                src={rightArrow}
                alt='right arrow icon'
                onClick={nextSlide}
            />
            {slidesLenght > 1 && (
                <ul className='slidecontainer__list'>{` ${currentImage + 1} / ${slidesLenght}`}</ul>
            )}
        </div>
    );
};

export default SlideShow;
