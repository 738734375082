import React, { useLayoutEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Collapse from '../components/Collapse.jsx';
import data from '../data/cv.json';
import { useTheme } from '../outils/Hooks.jsx';
import { LngContext } from '../outils/Context.jsx';
import github from '/images/icons/social/github.png';
import mail from '/images/icons/social/mail.png';
import linkedin from '/images/icons/social/linkedin.png';

import AnimatedPage from '../components/AnimatedPage.jsx';

//Contact images
import reactIcon from '/images/icons/pink/React.png';
import jsIcon from '/images/icons/pink/js.png';
import htmlIcon from '/images/icons/pink/html5.png';
import threeIcon from '/images/icons/pink//three.png';
import cssIcon from '/images/icons/pink/css3.png';
import nodeIcon from '/images/icons/pink/nodejs.png';
import vsIcon from '/images/icons/pink/vscode.png';
import gitIcon from '/images/icons/pink/git.png';
import githubIcon from '/images/icons/pink/github.png';
import sassIcon from '/images/icons/pink/Sass.png';

const About = () => {
    const { theme } = useTheme();
    const { language } = useContext(LngContext);

    const cvData = data?.filter((element) => element.lang === language);
    const cvIntroduction = cvData[0]?.introduction;
    const cvExperiences = cvData[0]?.experiences;

    const cvExperiencesElements = cvExperiences?.map((data, index) => {
        if (!data) return null;
        return (
            <Collapse
                index={index}
                theme={theme}
                key={data.id}
                title={data.title}
                subtitile={data.subtitle}
                dateIn={data.date.in}
                dateOut={data.date.out}
                period={data.period}
                content={data.description}
                open={false}
            />
        );
    });

    const cvFormations = cvData[0]?.education;
    const cvFormationsElements = cvFormations?.map((data, index) => {
        if (!data) return null;
        return (
            <Collapse
                theme={theme}
                index={index + 4}
                key={data.id}
                title={data.title}
                subtitile={data.subtitle}
                dateIn={data.date.in}
                dateOut={data.date.out}
                period=''
                content={data.description}
                open={false}
            />
        );
    });

    // Navigation starts at the top of the page
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    if (!cvIntroduction[0]) return;
    return (
        <AnimatedPage>
            <div
                className={`${
                    theme === 'light'
                        ? 'pageTitle light pageTitle__text'
                        : 'pageTitle dark pageTitle__text'
                }`}
            >
                <h1>{cvIntroduction[0].title}</h1>
            </div>
            <div className={`${theme === 'light' ? 'siteWrapper light' : 'siteWrapper dark'}`}>
                <div
                    className={`${
                        theme === 'light'
                            ? 'contact__text contact--light'
                            : 'contact__text contact--dark'
                    }`}
                >
                    <p>{cvIntroduction[0].description}</p>
                </div>
            </div>
            <div className={`${theme === 'light' ? ' siteWrapper light' : 'siteWrapper dark'}`}>
                <div className='container'>
                    <div className='cv'>
                        <section className={`${theme === 'light' ? 'light' : 'dark'}`}>
                            <h2 className='cv--margin'>{cvIntroduction[0].subtitle[0]}</h2>
                            <ul className='cv__contact cv--border'>
                                <li>
                                    <button
                                        className={`${
                                            theme === 'light'
                                                ? `btn btn--contact`
                                                : 'btn dark btn--contact'
                                        }`}
                                    >
                                        <Link
                                            to='mailto:rafaelasodev@gmail.com'
                                            target='_blank'
                                            className={`${
                                                theme === 'light' ? `cv__icon` : `cv__icon dark`
                                            }`}
                                        >
                                            <img
                                                src={mail}
                                                alt='mail icon'
                                            />
                                            {language === 'FR'
                                                ? 'Mail'
                                                : language === 'EN'
                                                ? 'Email'
                                                : 'Email'}
                                        </Link>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={`${
                                            theme === 'light'
                                                ? `btn btn--contact`
                                                : 'btn dark btn--contact'
                                        }`}
                                    >
                                        <Link
                                            to='https://github.com/rafaeladev'
                                            target='_blank'
                                            className={`${
                                                theme === 'light' ? `cv__icon` : `cv__icon dark`
                                            }`}
                                        >
                                            <img
                                                src={github}
                                                alt='github icon'
                                            />
                                            Github
                                        </Link>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={`${
                                            theme === 'light'
                                                ? `btn btn--contact`
                                                : 'btn dark btn--contact'
                                        }`}
                                    >
                                        <Link
                                            to='https://www.linkedin.com/in/rafaeladsdo/'
                                            target='_blank'
                                            className={`${
                                                theme === 'light' ? `cv__icon` : `cv__icon dark`
                                            }`}
                                        >
                                            <img
                                                src={linkedin}
                                                alt='linkedin icon'
                                            />
                                            LinkedIn
                                        </Link>
                                    </button>
                                </li>
                            </ul>
                        </section>
                        <section className={`${theme === 'light' ? 'light' : 'dark'}`}>
                            <h2 className='cv--margin'>{cvIntroduction[0].subtitle[1]}</h2>
                            <ul className='iconsList cv--border'>
                                <li className='skills__item skills--pink'>
                                    <img
                                        className={`tagType`}
                                        src={htmlIcon}
                                        alt='HTML icon'
                                    />
                                    <p className='skills__text skills--size skills__text--accent'>
                                        HTML
                                    </p>
                                </li>

                                <li className='skills__item skills--pink'>
                                    <img
                                        className={`tagType`}
                                        src={cssIcon}
                                        alt='CSS icon'
                                    />
                                    <p className='skills__text skills--size skills__text--accent'>
                                        CSS
                                    </p>
                                </li>
                                <li className='skills__item skills--pink'>
                                    <img
                                        className={`tagType`}
                                        src={jsIcon}
                                        alt='JavaScript icon'
                                    />
                                    <p className='skills__text skills--size skills__text--accent'>
                                        JavaScript
                                    </p>
                                </li>

                                <li className='skills__item skills--pink'>
                                    <img
                                        className={`tagType`}
                                        src={reactIcon}
                                        alt='React icon'
                                    />
                                    <p className='skills__text skills--size skills__text--accent'>
                                        React
                                    </p>
                                </li>
                                <li className='skills__item skills--pink'>
                                    <img
                                        className={`tagType`}
                                        src={sassIcon}
                                        alt='SCSS icon'
                                    />
                                    <p className='skills__text skills--size skills__text--accent'>
                                        SASS
                                    </p>
                                </li>

                                <li className='skills__item skills--pink'>
                                    <img
                                        className={`tagType`}
                                        src={nodeIcon}
                                        alt='NodeJS icon'
                                    />
                                    <p className='skills__text skills--size skills__text--accent'>
                                        Node JS
                                    </p>
                                </li>

                                <li className='skills__item skills--pink'>
                                    <img
                                        className={`tagType`}
                                        src={threeIcon}
                                        alt='Three.js icon'
                                    />
                                    <p className='skills__text skills--size skills__text--accent'>
                                        Three.js
                                    </p>
                                </li>
                                <li className='skills__item skills--pink'>
                                    <img
                                        className={`tagType`}
                                        src={vsIcon}
                                        alt='Visual Studio Code icon'
                                    />
                                    <p className='skills__text skills--size skills__text--accent'>
                                        VS Code
                                    </p>
                                </li>
                                <li className='skills__item skills--pink'>
                                    <img
                                        className={`tagType`}
                                        src={gitIcon}
                                        alt='Git icon'
                                    />
                                    <p className='skills__text skills--size skills__text--accent'>
                                        Git
                                    </p>
                                </li>
                                <li className='skills__item skills--pink'>
                                    <img
                                        className={`tagType`}
                                        src={githubIcon}
                                        alt='Github icon'
                                    />
                                    <p className='skills__text skills--size skills__text--accent'>
                                        Github
                                    </p>
                                </li>
                            </ul>
                        </section>

                        <section className={`${theme === 'light' ? 'light' : 'dark'}`}>
                            <h2 className='cv--margin'>{cvIntroduction[0].subtitle[2]}</h2>
                            <div className='cv__collapses cv--border'>{cvExperiencesElements}</div>
                        </section>

                        <section className={`${theme === 'light' ? 'light' : 'dark'}`}>
                            <h2 className='cv--margin'>{cvIntroduction[0].subtitle[3]}</h2>
                            <div className='cv__collapses cv--border'>{cvFormationsElements}</div>
                        </section>
                    </div>
                </div>
            </div>
        </AnimatedPage>
    );
};

export default About;
