import React, { useContext, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import Scene from '../components/threejs/Scene.jsx';
import Card from '../components/Card.jsx';
import Competences from '../components/Competences.jsx';
import { useTheme } from '../outils/Hooks.jsx';
import { LngContext } from '../outils/Context.jsx';
import projects from '../data/projectsCards.json';
import data from '../data/home.json';
import PagesLayout from '../components/PagesLayout.jsx';
import { Leva } from 'leva';
import GearsScene from '../components/threejs/GearsScene.jsx';
import CodeScene from '../components/threejs/CodeScene.jsx';
import { Reveal } from '../outils/Reveal.tsx';

import AnimatedPage from '../components/AnimatedPage.jsx';

const Home = () => {
    const { theme } = useTheme();
    const { language } = useContext(LngContext);

    const eventHandler = (event) => {
        event.stopPropagation();
    };

    // useEffect(() => {
    //     const observer = new IntersectionObserver((entries) => {
    //         entries.forEach((entry) => {
    //             if (entry.isIntersecting) {
    //                 entry.target.classList.add('text-show');
    //             } else {
    //                 // entry.target.classList.remove('show');
    //             }
    //         });
    //     });
    //     const hiddenElements = document.querySelectorAll('.text-hidden');
    //     hiddenElements.forEach((el) => observer.observe(el));
    // });

    /**
     * Cursor
     */
    const cursor = {};
    cursor.x = 0;
    cursor.y = 0;

    const projectsCards = projects.map((data, index) => {
        if (!data) return;
        return (
            <Card
                key={index}
                anim={index + 1}
                id={`projects/${data.id}`}
                title={data.title}
                subtitle={data.subtitle}
                tags={data.tags}
                category={data.category}
                description={data.description}
                coverImage={data.coverImage}
                lang={language}
            />
        );
    });

    const content = data.find((content) => content.lang === language);
    if (!content) {
        return;
    }

    //window size
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    /**
     * Resize
     */
    window.addEventListener('resize', () => {
        if (sizes.width <= 768) {
        } else if (768 < sizes.width <= 992) {
        } else {
        }
    });

    return (
        <AnimatedPage>
            {/* <> */}
            <section className='wellcomeWrapper'>
                <div className='canvasWrapper'>
                    <Leva
                        collapsed
                        hidden={true}
                    />
                    <Canvas
                        camera={{
                            // fov: 65,
                            near: 0.1,
                            far: 200,
                            position: [-4, 3, 6],
                        }}
                    >
                        <Scene />
                    </Canvas>
                </div>

                <div
                    className={`wellcomeBloc transition ${
                        theme === 'light'
                            ? 'lightBack container siteWrapper'
                            : 'darkBack container siteWrapper'
                    }`}
                >
                    <Reveal>
                        <div className='skills bienvenue hidden'>
                            <div>
                                <h1 className='skills__title pageTitle__text '>{content.title}</h1>
                                <p className='skills__text'>{content?.text}</p>
                            </div>

                            <div className='skills skills--column'>
                                <p className='skills__subtitle'>{content?.subtitles[0]}</p>
                                <ul className='skills--row'>
                                    <li className='skills__text skills__text--accent'>
                                        JavaScript
                                    </li>
                                    <li className='skills__text skills__text--accent'>three.js</li>
                                    <li className='skills__text skills__text--accent'>React</li>
                                    <li className='skills__text skills__text--accent'>HTML</li>
                                    <li className='skills__text skills__text--accent'>CSS</li>
                                </ul>
                            </div>
                        </div>
                    </Reveal>
                </div>
            </section>
            <section
                className={`${
                    theme === 'light'
                        ? 'light competenceList siteWrapper'
                        : 'dark competenceList siteWrapper'
                }`}
            >
                <article className='competence__description'>
                    <Reveal>
                        <div className='competence--size competence--left'>
                            <Competences
                                title={content?.skills?.title[0]}
                                description={content?.skills?.text[0]}
                                align='--right'
                            />
                            <div className='canvasWrapperCompetences'>
                                <Canvas
                                    camera={{ fov: 29 }}
                                    onClick={eventHandler}
                                >
                                    <CodeScene />
                                </Canvas>
                            </div>
                        </div>
                    </Reveal>
                </article>
                <article className='competence__description competence--border'>
                    <Reveal>
                        <div className='canvasWrapperCompetences'>
                            <Canvas
                                camera={{ fov: 29 }}
                                onClick={eventHandler}
                            >
                                <GearsScene />
                            </Canvas>
                        </div>
                        <div className='competence--separation competence--size competence--right'>
                            <Competences
                                title={content?.skills?.title[1]}
                                description={content?.skills?.text[1]}
                                align='--left'
                            />
                        </div>
                    </Reveal>
                </article>
            </section>

            <section id='Projects'>
                <div
                    className={
                        theme === 'light'
                            ? `projectSection__title light`
                            : `projectSection__title dark`
                    }
                >
                    <h2 id='Projects'>{content?.subtitles[1]}</h2>
                </div>
                <PagesLayout theme={theme}>
                    <div
                        id='Projects'
                        className='cardList'
                    >
                        {projectsCards}
                    </div>
                </PagesLayout>
            </section>
        </AnimatedPage>
    );
};

export default Home;
