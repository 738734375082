import React from 'react';
import { useContext } from 'react';
import { LngContext } from '../outils/Context.jsx';

const ChangeLanguage = () => {
    const { language, selectLanguage } = useContext(LngContext);

    return (
        <>
            {/* <label for='pet-select'> Choose a pet:</label> */}
            <select
                id='pet'
                className='selectBox'
                onChange={(event) => selectLanguage(event)}
                name='language selection box'
                value={language}
                aria-labelledby='state'
            >
                <option
                    className='optionsMenu'
                    value='EN'
                    aria-labelledby='english'
                >
                    EN
                </option>
                <option
                    className='optionsMenu'
                    value='FR'
                    aria-labelledby='français'
                >
                    FR
                </option>
                <option
                    className='optionsMenu'
                    value='BR'
                    aria-labelledby='português'
                >
                    BR
                </option>
            </select>
        </>
    );
};

export default ChangeLanguage;
