import React, { useEffect } from 'react';
import { useTheme } from '../outils/Hooks.jsx';
import colors from '../outils/Colors.jsx';
import codeIcon from '/images/icons/pink/code.png';
import engIcon from '/images/icons/pink/eng.png';

const Competences = (props) => {
    const { theme } = useTheme();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('text-show');
                } else {
                    entry.target.classList.remove('text-show');
                }
            });
        });
        const hiddenElements = document.querySelectorAll('.text-hidden');
        hiddenElements.forEach((el) => observer.observe(el));
    });

    const cardBackground = {
        backgroundColor: `${theme === 'light' ? '#ffffff' : colors.fondFonce}`,
        color: `${theme === 'light' ? colors.text : '#ffffff'}`,
        // border: `1px solid ${theme === 'light' ? colors.containersClairs : colors.piment}`,
    };
    return (
        <div className='competence'>
            <div className={`competence__title competence${props.align}`}>
                <h2 className={`competence${props.align}`}>{props.title}</h2>
            </div>
            <div
                className={`competence__description competence${props.align}`}
                style={{ width: '100%', padding: '0' }}
            >
                <p className='text-hidden'>{props.description}</p>
            </div>
        </div>
    );
};

export default Competences;
