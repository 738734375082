import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Nav from './Nav.jsx';
import { useTheme } from '../outils/Hooks.jsx';

const PagesLayout = ({ children }) => {
    const { theme } = useTheme();
    return (
        <div
            className={` transition ${
                theme === 'light'
                    ? 'lightBack container siteWrapper'
                    : 'darkBack container siteWrapper'
            }`}
        >
            {children}
        </div>
    );
};

export default PagesLayout;
