import React from 'react';
import github from '/images/icons/social/github.webp';
import twitter from '/images/icons/social/twitter.webp';
import linkedin from '/images/icons/social/linkedin.webp';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer__text'>
                <p>
                    By <Link to='mailto:rafaelasodev@gmail.com'>Rafaela Oliveira</Link> @ 2023
                </p>
                <div className='footer__icons'>
                    <figure>
                        <Link
                            to='https://github.com/rafaeladev'
                            target='_blank'
                        >
                            <img
                                src={github}
                                alt='github icon'
                            />
                        </Link>
                    </figure>
                    <figure>
                        <Link
                            to='https://twitter.com/rafaelarsdo'
                            target='_blank'
                        >
                            <img
                                src={twitter}
                                alt='twitter icon'
                                style={{ width: '34.5px' }}
                            />
                        </Link>
                    </figure>
                    <figure>
                        <Link
                            to='https://www.linkedin.com/in/rafaeladsdo/'
                            target='_blank'
                        >
                            <img
                                src={linkedin}
                                alt='linkedin icon'
                            />
                        </Link>
                    </figure>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
