import React, { Suspense, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { PerspectiveCamera, PresentationControls } from '@react-three/drei';
import Banner from './Banner.jsx';
import Particles from './Particles.jsx';
import { useTheme } from '../../outils/Hooks.jsx';

const Scene = () => {
    //Theme
    const { theme } = useTheme();

    const positions = { x: 0, z: 10 };

    //window size
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    // Camera position
    let cameraY = -0.5;
    let hFOV = 0;

    if (sizes.width < 768) {
        hFOV = 40;
        positions.x = -2;
    } else if (sizes.width >= 768 && sizes.width < 992) {
        hFOV = 35;
        positions.x = 2.5;
    } else {
        hFOV = 45;
    }

    let fov =
        (Math.atan(Math.tan((hFOV * Math.PI) / 360) / (sizes.width / sizes.height)) * 360) /
        Math.PI;

    // Objects distance for each section
    const objectsDistance = 1.5;
    let currentSection = 0;

    //Scroll
    let scrollY = window.scrollY;

    // useEffect(() => {
    window.addEventListener('scroll', () => {
        scrollY = window.scrollY;
        const newSection = Math.round(scrollY / sizes.height);
        if (newSection != currentSection) {
            currentSection = newSection;
        }
    });
    // }, []);

    //Animations-Updates
    useFrame((state, delta) => {
        // Animate camera
        state.camera.position.y = (-scrollY / sizes.height) * objectsDistance * 2;
        state.camera.rotation.x = 0;
        cameraY = state.camera.position.y;
        //     window.addEventListener('resize', () => {
        //         // Update sizes
        //         sizes.width = window.innerWidth;
        //         sizes.height = window.innerHeight;

        //         if (sizes.width < 768) {
        //             hFOV = 35;
        //             positions.x = -1.5;
        //         } else if (sizes.width >= 768 && sizes.width < 992) {
        //             hFOV = 55;
        //             positions.x = 0;
        //         } else {
        //             hFOV = 75;
        //             positions.x = 0;
        //         }
        //         state.camera
        //             ? (state.camera.fov =
        //                   (Math.atan(Math.tan((hFOV * Math.PI) / 360) / (sizes.width / sizes.height)) *
        //                       360) /
        //                   Math.PI)
        //             : null;
        // });
    });

    return (
        <>
            <directionalLight
                position={[1, 2, 3]}
                intensity={1.5}
            />

            <PerspectiveCamera
                makeDefault={true}
                fov={fov}
                near={1}
                far={200}
                position-x={positions.x}
                position-z={positions.z}
            />
            <group>
                <Suspense fallback={null}>
                    <Banner
                        theme={theme}
                        cameraY={cameraY}
                        dist={-objectsDistance * 0}
                    />
                </Suspense>
                <PresentationControls
                    position={[0, 0, 0]}
                    rotation={[0, 0, 0]}
                    azimuth={[-0, 0]}
                >
                    <Suspense fallback={'Loading...'}>
                        <Particles
                            theme={theme}
                            dist={-objectsDistance * 0.8}
                            width={sizes.width}
                            height={sizes.height}
                        />
                    </Suspense>
                </PresentationControls>
            </group>
        </>
    );
};

export default Scene;
