import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { FaAngleLeft } from 'react-icons/fa';

const Collapse = (props) => {
    const [toggle, setToggle] = useState(false);
    const [heightEl, setHeightEl] = useState();
    const refHeight = useRef();

    const text = props.content.map((description, index) => (
        <li
            key={index}
            aria-hidden={toggle ? 'true' : 'false'}
        >
            {description}
        </li>
    ));

    useEffect(() => {
        setHeightEl(`${refHeight.current.scrollHeight * 1.1}px`);
    }, []);

    const toggleState = () => {
        setToggle(!toggle);
    };
    return (
        <article
            className={`${
                props.theme === 'light'
                    ? 'collapse collapse--margin light'
                    : 'collapse collapse--margin dark'
            }`}
        >
            <div className='collapse'>
                <button
                    className='collapse__visible'
                    onClick={toggleState}
                >
                    <div>
                        <h3>
                            {props.title} <span>({props.subtitile})</span>
                        </h3>

                        <p
                            className='cv__date'
                            style={{ color: `${props.theme === 'light' ? '#454545' : '#ffffff'}` }}
                        >
                            <time>{props.dateIn} </time>-<time> {props.dateOut}</time>
                            {props.period && <span> ({props.period})</span>}
                        </p>
                    </div>
                    <FaAngleLeft className={toggle && 'active'} />
                </button>

                <ul
                    className={toggle ? 'collapse__toggle animated' : 'collapse__toggle'}
                    style={{ height: toggle ? `${heightEl}` : '0px' }}
                    ref={refHeight}
                >
                    {text}
                </ul>
            </div>
        </article>
    );
};

export default Collapse;
