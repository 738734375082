import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../outils/Hooks.jsx';

const Error = () => {
    const { theme } = useTheme();
    return <h1 className='error'>Error 404</h1>;
};

export default Error;
