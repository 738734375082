import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer.jsx';
import Nav from './Nav.jsx';
import { useTheme } from '../outils/Hooks.jsx';
import { LngContext } from '../outils/Context.jsx';
import Box from './Box.jsx';

import { BsFillSunFill } from 'react-icons/bs';
import { BsFillMoonStarsFill } from 'react-icons/bs';

const Layout = () => {
    const { toggleTheme, theme } = useTheme();
    const { language } = useContext(LngContext);

    const content = theme === 'light' ? <BsFillMoonStarsFill /> : <BsFillSunFill />;

    return (
        <>
            <header className='headerbar'>
                <Nav />
            </header>
            <div className={theme === 'light' ? 'site site--light' : 'site site--dark'}>
                <main>
                    <button
                        aria-label='Theme switch'
                        className='theme-switch'
                        onClick={toggleTheme}
                    >
                        {content}
                    </button>
                    <Outlet />
                </main>
                <section className='Work'>
                    <div className={theme === 'light' ? `box` : `box dark`}>
                        <Box
                            theme={theme}
                            lang={language}
                        />
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
};

export default Layout;
