import React from 'react';
import { Link } from 'react-router-dom';
import SlideShow from './SlideShow.jsx';
import colors from '../outils/Colors.jsx';

const ProjectSection = (props) => {
    const bG = {
        backgroundColor: `${
            props.theme === 'light' ? colors.pageContainerClair : colors.pageContainerFonce
        }`,
        color: `${props.theme === 'light' ? colors.text : '#ffffff'}`,
    };

    const languagues = props.tags.map((props, index) => (
        <li
            key={index}
            className={`tagType tagType--${props}`}
        >
            <div className='tagType__tooltip'>{props}</div>
        </li>
    ));

    const title = props?.title.find((element) => element.lang === props.lang);
    const sections = props.sections.find((element) => element.lang === props.lang);
    const long = props.objectifs ? props.objectifs[props.lang].length : 1;
    const objectifs = props.objectifs
        ? props.objectifs[props.lang].map((props, index) => (
              <li
                  key={index}
                  className='ProjectDetail__listItem'
              >
                  <p
                      key={props.key}
                      style={{
                          backgroundColor: `${
                              (index + 1) / long <= 0.5 ? colors.containersClairs : colors.piment
                          }`,
                          opacity: `${
                              (index + 1) / long <= 0.5
                                  ? 1 - (index + 1) / long
                                  : ((index + 1) / long) * 2 - 1
                          }`,
                      }}
                  ></p>
                  <p key={props.index}>{props}</p>
              </li>
          ))
        : null;

    return (
        <>
            <div className='ProjectDetail__head ProjectDetail__head--left'>
                <div className='ProjectDetail__column'>
                    <figure className={`projects projects--1`}>
                        <img
                            className='ProjectDetail__title'
                            src={props.logo}
                        />
                    </figure>
                    <ul className='ProjectDetail__languages'>{languagues}</ul>
                </div>
                <div className='ProjectDetail__column ProjectDetail__column--size'>
                    {title.text && <h2 className='ProjectDetail__subtitle'>{title.text}</h2>}
                    <div className='ProjectDetail__url'>
                        {props.urls ? (
                            props.urls.site ? (
                                <button className='btn btn-contact'>
                                    <Link
                                        target='_blank'
                                        to={props.urls.site}
                                    >
                                        {props.lang === 'FR'
                                            ? 'Lien du site'
                                            : props.lang === 'EN'
                                            ? 'Website preview'
                                            : 'Link do site'}
                                    </Link>
                                </button>
                            ) : null
                        ) : null}
                        {props.urls ? (
                            props.urls.github ? (
                                <button className='btn btn-contact'>
                                    <Link
                                        target='_blank'
                                        to={props.urls.github}
                                    >
                                        {props.lang === 'FR'
                                            ? 'Dépôt Github'
                                            : props.lang === 'EN'
                                            ? 'Github repository'
                                            : 'Link do Github'}
                                    </Link>
                                </button>
                            ) : null
                        ) : null}
                        {props.urls ? (
                            props.urls.vercel ? (
                                <button className='btn btn-contact'>
                                    <Link
                                        target='_blank'
                                        to={props.urls.vercel}
                                    >
                                        {props.lang === 'FR'
                                            ? 'Lien du site'
                                            : props.lang === 'EN'
                                            ? 'Website preview'
                                            : 'Link do site'}
                                    </Link>
                                </button>
                            ) : null
                        ) : null}
                    </div>
                </div>
            </div>
            <div className='ProjectDetail'>
                {props.sections ? <p className='ProjectDetail__block'>{sections.section1}</p> : ''}
                {sections.section2 ? (
                    <p className='ProjectDetail__block'>{sections.section2}</p>
                ) : null}
                {sections.section3 ? (
                    <p className='ProjectDetail__block'>{sections.section3}</p>
                ) : null}
                <div className='ProjectDetail__block ProjectDetail__section'>
                    <article className='slideShow'>
                        <SlideShow imagesSrc={props.slidesImages} />
                        <span>{props.slidesImagesCaption}</span>
                    </article>
                    <aside>
                        <p className='ProjectDetail__list--title'>
                            {props.lang === 'FR'
                                ? `J'ai réalisé :`
                                : props.lang === 'EN'
                                ? 'My achievements:'
                                : 'Eu desenvolvi:'}
                        </p>
                        <ul className='ProjectDetail__list'>{objectifs}</ul>
                    </aside>
                </div>
            </div>
        </>
    );
};

export default ProjectSection;
