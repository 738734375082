import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import ChangeLanguage from './ChangeLanguage.jsx';
import { useContext, useState, useRef } from 'react';
import { ThemeContext } from '../outils/Context.jsx';
import { LngContext } from '../outils/Context.jsx';
import data from '../data/home.json';
import colors from '../outils/Colors.jsx';
import BurgerMenu from './BurgerMenu.jsx';

const Nav = () => {
    const { toggleTheme, theme } = useContext(ThemeContext);
    const { language } = useContext(LngContext);

    const navRef = useRef();

    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    };

    const navText = data?.filter((element) => element.lang === language);

    const textColor = {
        color: `${theme === 'light' ? colors.text : '#ffffff'}`,
    };

    const backgroundColor = {
        backgroundColor: `${
            theme === 'light' ? colors.pageContainerClair : colors.pageContainerFonce
        }`,
    };

    const textColorActif = {
        color: colors.colorPiment,
        // backgroundColor: `${
        //     theme === 'light' ? colors.pageContainerClair : colors.pageContainerFonce
        // }`,
    };

    // Nav scroll color change
    let scrollY = window.scrollY;
    window.addEventListener('scroll', () => {
        scrollY = window.scrollY;
        if (scrollY > 100) {
            navRef.current.classList.add('nav--color-change');
        } else {
            navRef.current.classList.remove('nav--color-change');
        }
    });

    //window size
    const sizes = {
        width: window.innerWidth,
    };

    return (
        <nav
            ref={navRef}
            className={`${theme === 'light' ? 'nav' : 'nav dark'} `}
        >
            <ul className='nav__link siteWrapper'>
                <li>
                    <Link to='/'>
                        <figure>
                            <img
                                className='nav__link__logo'
                                src='/images/favicon2.webp'
                                alt='portfolio logo'
                            />
                        </figure>
                    </Link>
                </li>
                <li>
                    <NavLink
                        to='/'
                        className={({ isActive }) =>
                            isActive
                                ? 'nav__link--item nav__link--active'
                                : 'nav__link--item nav__link--foccus'
                        }
                    >
                        {navText[0]?.nav[0]}
                    </NavLink>

                    <NavLink
                        to='/projects'
                        className={({ isActive }) =>
                            isActive
                                ? 'nav__link--item nav__link--active'
                                : 'nav__link--item nav__link--foccus'
                        }
                    >
                        {navText[0]?.nav[1]}
                    </NavLink>

                    <NavLink
                        to='/about'
                        className={({ isActive }) =>
                            isActive
                                ? 'nav__link--item nav__link--active'
                                : 'nav__link--item nav__link--foccus'
                        }
                    >
                        {navText[0]?.nav[2]}
                    </NavLink>
                </li>
                <li className='nav__lang'>
                    <ChangeLanguage />
                </li>
                <li>
                    <NavLink
                        to='mailto:rafaelarsdo@gmail.com'
                        className={'nav__link--item nav__link__contact'}
                        style={({ isActive }) => (isActive ? textColorActif : textColor)}
                    >
                        {navText[0]?.nav[3]}
                    </NavLink>
                </li>
            </ul>
            {/* Début Burger menu */}
            <ul className='nav__hamburger siteWrapper'>
                <li>
                    <Link to='/'>
                        <figure>
                            <img
                                className='nav__link__logo'
                                src='/images/favicon2.webp'
                                alt='portfolio logo'
                            />
                        </figure>
                    </Link>
                </li>
                <li onClick={toggleHamburger}>
                    <BurgerMenu isOpen={hamburgerOpen} />
                </li>

                <li className='nav__lang'>
                    <ChangeLanguage />
                </li>
            </ul>
            <ul
                className={hamburgerOpen ? 'hamburger__menu hamburger--open' : 'hamburger__menu'}
                style={backgroundColor}
                onClick={toggleHamburger}
            >
                <li>
                    <NavLink
                        to='/'
                        className={({ isActive }) =>
                            isActive
                                ? 'nav__link--item nav__link--active'
                                : 'nav__link--item nav__link--foccus'
                        }
                        onClick={toggleHamburger}
                    >
                        {navText[0]?.nav[0]}
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        to='/projects'
                        className={({ isActive }) =>
                            isActive
                                ? 'nav__link--item nav__link--active'
                                : 'nav__link--item nav__link--foccus'
                        }
                        onClick={toggleHamburger}
                    >
                        {navText[0]?.nav[1]}
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        to='/about'
                        className={({ isActive }) =>
                            isActive
                                ? 'nav__link--item nav__link--active'
                                : 'nav__link--item nav__link--foccus'
                        }
                        onClick={toggleHamburger}
                    >
                        {navText[0]?.nav[2]}
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        to='mailto:rafaelarsdo@gmail.com'
                        className={'nav__link--item nav__link__contact'}
                        style={({ isActive }) => (isActive ? textColorActif : textColor)}
                    >
                        {navText[0]?.nav[3]}
                    </NavLink>
                </li>
                {/* Fin Burger menu */}
            </ul>
        </nav>
    );
};

export default Nav;
