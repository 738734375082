import { Float, Text3D } from '@react-three/drei';
import { useControls } from 'leva';
import { useRef } from 'react';
import { pageContainerClair, pageContainerFonce } from '../../styles/main.module.scss';

const Banner = (props) => {
    const nom = useRef();
    const prenom = useRef();
    const devNom = useRef();

    let sizePrenom = 0;
    let sizeNom = 0;
    let sizeDev = 0;
    let positionNom = { x: -1.8, z: -5 };
    let x = 0;
    let y = 0;

    //window size
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    if (sizes.width < 768) {
        sizePrenom = 1.1;
        sizeNom = 0.65;
        x = 4.5;
        y = 1;
        positionNom.x = -6.5;
        sizeDev = 0.8;
    } else if (sizes.width >= 768 && sizes.width < 992) {
        sizePrenom = 1.2;
        sizeNom = 0.7;
        positionNom.x = -0.1;
        sizeDev = 0.9;
        x = 3;
    } else {
        sizePrenom = 0.9;
        sizeNom = 0.5;
        sizeDev = 0.8;
        x = 1;
    }

    //Html text style
    const activeStyle = {
        backgroundColor: `${props.theme === 'light' ? pageContainerClair : pageContainerFonce}`,
        width: '90%',
        borderRadius: '15px',
        padding: '20px',
        marginBottom: '20px',
    };

    // Leva Debug UI parameters
    const { color, height, position } = useControls('Prenom', {
        color: '#e54f6d',

        height: { value: 0.1, min: 0.1, max: 10 },
        position: {
            value: { x: -6.5, z: -5 },
            min: -10,
            max: 10,
            step: 0.5,
        },
    });

    const { colorTextDev } = useControls('TextDev', {
        colorTextDev: '#969696',
    });

    const { height2 } = useControls('Nom', {
        // size2: { value: 0.4, min: 0.1, max: 5 },
        height2: { value: 0.1, min: 0.1, max: 10 },
        // position2: {
        //     value: { x: -1.5, z: -5 },
        //     min: -10,
        //     max: 10,
        //     step: 0.5,
        // },
    });

    const actstyle = {
        transform: `translate3d(0px,0px,0px) `,
    };

    return (
        <>
            <ambientLight intensity={0.5} />
            <Float
                rotationIntensity={0.5}
                floatIntensity={0.5}
            >
                <Text3D
                    ref={prenom}
                    font='./fonts/Kadwa_Regular.json'
                    size={sizePrenom}
                    height={height}
                    curveSegments={12}
                    bevelEnabled
                    bevelThickness={0.02}
                    bevelSize={0.02}
                    bevelOffset={0}
                    bevelSegments={5}
                    position-y={props.cameraY + x + y}
                    style={actstyle}
                    position-x={position.x}
                    position-z={position.z}
                >
                    Rafaela
                    <meshStandardMaterial color={color} />
                </Text3D>
                <Text3D
                    ref={nom}
                    font='./fonts/Kadwa_Regular.json'
                    size={sizeNom}
                    height={height2}
                    curveSegments={12}
                    bevelEnabled
                    bevelThickness={0.02}
                    bevelSize={0.02}
                    bevelOffset={0}
                    bevelSegments={5}
                    position-y={props.cameraY + x}
                    style={actstyle}
                    position-x={positionNom.x}
                    position-z={positionNom.z}
                >
                    DA SILVA DE OLIVEIRA
                    <meshStandardMaterial color={color} />
                </Text3D>
                <Text3D
                    ref={devNom}
                    font='./fonts/Metrophobic_Regular.json'
                    size={sizeDev}
                    height={height2}
                    curveSegments={12}
                    bevelEnabled
                    bevelThickness={0.02}
                    bevelSize={0.02}
                    bevelOffset={0}
                    bevelSegments={5}
                    position-y={props.cameraY - 1.1 + x}
                    position-x={-6.5}
                    position-z={-5}
                >
                    Web Developer
                    <meshStandardMaterial color={colorTextDev} />
                </Text3D>
            </Float>
        </>
    );
};

export default Banner;
