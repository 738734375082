const colors = {
    piment: '#e54f6d', // pink // gray
    fondClair: '#f5ebe0', // beige clair
    fondFonce: '#514846', // beige foncé
    ombresClaires: '#e3d5ca', // beige moyen
    ombresFonces: '#75675f', // beige foncé
    containersClairs: ' #d5bdaf', // marron moyen
    containersFonces: '#897b72', // marron foncé
    pageContainerClair: 'rgb(247,240,232)',
    pageContainerFonce: 'rgb(86,77,75)',
    text: '#454545',
    mix: 'rgb(223,123,136)',
};

export default colors;
