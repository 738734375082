import React, { useLayoutEffect, useContext } from 'react';
import { Link, useLocation, useParams, useSearchParams, Navigate } from 'react-router-dom';
import SlideShow from '../components/SlideShow.jsx';
import projectsData from '../data/projects.json';
import PagesLayout from '../components/PagesLayout.jsx';

import { useTheme } from '../outils/Hooks.jsx';
import { LngContext } from '../outils/Context.jsx';
import ProjectSection from '../components/ProjectSection.jsx';
import Error from './Error.jsx';

import AnimatedPage from '../components/AnimatedPage.jsx';

const ProjectDetail = () => {
    const { theme } = useTheme();
    const { language } = useContext(LngContext);
    const params = useParams();
    const location = useLocation();
    const type = location.state?.type || 'all';
    const [searchParams, setSearchParams] = useSearchParams();
    let pageTitle = null;
    let title1 = null;

    const projectName = projectsData.find((project) => project.id === params.id);
    const projects = projectsData.filter((project) => project.id === params.id);
    const title = projectName?.title.find((element) => element.lang === language);

    if (language === 'FR') {
        title1 = 'Projet';
        if (params.id === 'openclassrooms') {
        } else if (params.id === 'scrimba') {
            pageTitle = `${title1}s de la formation Scrimba`;
        } else if (params.id === 'threejs') {
            pageTitle = `${title1}s de la formation Three.js journey`;
        } else {
            pageTitle = ` ${title1} ${title?.text}`;
        }
    } else if (language === 'EN') {
        title1 = 'Project';
        if (params.id === 'openclassrooms') {
            pageTitle = `OpenClassrooms Training ${title1}s`;
        } else if (params.id === 'scrimba') {
            pageTitle = `Scrimba Training ${title1}s`;
        } else if (params.id === 'threejs') {
            pageTitle = `Three.js Journey Training ${title1}s`;
        } else {
            pageTitle = ` ${title?.text} ${title1}`;
        }
    } else {
        title1 = 'Projeto';
        if (params.id === 'openclassrooms') {
            pageTitle = `${title1}s da formação OpenClassrooms`;
        } else if (params.id === 'scrimba') {
            pageTitle = `${title1}s da formação Scrimba`;
        } else if (params.id === 'threejs') {
            pageTitle = `${title1}s da formação Three.js journey`;
        } else {
            pageTitle = ` ${title1}  ${title?.text}`;
        }
    }

    if (projectName) {
        let projectsDisplayed = [];
        const typeFilter = searchParams.get('languages');

        // Navigation starts at the top of the page
        useLayoutEffect(() => {
            window.scrollTo(0, 0);
        });

        // Filtrer les projets par categorie
        projectsDisplayed = typeFilter
            ? projects.filter((data) => data.tags === typeFilter)
            : projects;

        const projectsToDisplay = projects.map((data, index) => {
            if (!data) return;
            return (
                <section
                    className={`${
                        theme === 'light' ? 'ProjectDetail light' : 'ProjectDetail dark'
                    }`}
                    key={index}
                >
                    <div className='ProjectDetail__number'>
                        <div className='ProjectDetail__number--bar'></div>
                        <p>{`${index + 1} / ${projects.length}`}</p>
                        <div className='ProjectDetail__number--bar'></div>
                    </div>
                    <div
                        key={data.title}
                        className={`${
                            theme === 'light'
                                ? 'ProjectDetail__content'
                                : 'ProjectDetail__content dark'
                        }`}
                    >
                        <ProjectSection
                            theme={theme}
                            index={index}
                            key={data.key}
                            title={data.title}
                            logo={data.logo}
                            tags={data.tags}
                            category={data.category}
                            description={data.description}
                            objectifs={data.objectifs}
                            mobileImages={data.mobileImages}
                            urls={data.urls}
                            sections={data.sections}
                            slidesImages={data.slidesImages}
                            slidesImagesCaption={data.slidesImagesCaption}
                            length={projects.length}
                            lang={language}
                        />
                    </div>
                </section>
            );
        });
        return (
            <AnimatedPage>
                <div
                    className={`${
                        theme === 'light'
                            ? 'pageTitle light pageTitle__text'
                            : 'pageTitle dark pageTitle__text'
                    }`}
                >
                    <h1>{pageTitle}</h1>
                </div>
                <div
                    className='siteWrapper'
                    key={params.id}
                >
                    {projectsToDisplay}
                    <p className='ProjectDetail__back'>
                        <Link
                            className={`${theme === 'light' ? 'link' : 'link dark'}`}
                            to={'./..'}
                        >
                            {language === 'FR'
                                ? 'Retour page projects'
                                : language === 'EN'
                                ? 'Back to all projects page'
                                : 'Retornar à pagina com todos os projetos'}
                        </Link>
                    </p>
                </div>
            </AnimatedPage>
        );
    } else {
        return <Error />;
    }
};

export default ProjectDetail;
