import React from 'react';
import { useRef } from 'react';
import {
    Environment,
    useGLTF,
    PresentationControls,
    Float,
    ContactShadows,
    useAnimations,
} from '@react-three/drei';
import * as THREE from 'three';

const Model = () => {
    const directionalLight = useRef();
    const gearsRef = useRef();

    const gears = useGLTF('/models/gears/gears_compressed_v2.glb');

    const animations = useAnimations(gears.animations, gears.scene);

    const eventHandler = (e) => {
        e.stopPropagation();
        const action = animations.actions.gears_anim_big;
        const actionSmall = animations.actions.gears_anim_small;
        console.log(actionSmall);
        action.play();
        actionSmall.play();
        window.setTimeout(() => {
            action.stop();
            actionSmall.stop();
        }, 4000);
    };

    const handleDrag = (e) => {
        e.stopPropagation();
    };

    // gearsRef.addEventListener('drag', () => {
    //     console.log('you draged it!');
    // });

    // fill.addEventListener('dragstart', dragStart);

    return (
        <>
            <Environment
                path='/envMap/'
                files='potsdamer_platz_256.hdr'
            />
            <PresentationControls
                global
                rotation={[0.13, 0.1, 0]}
                polar={[-0.4, 0.2]}
                azimuth={[-1, 0.75]}
                config={{ mass: 2, tension: 400 }} // elastic effect
                snap={{ mass: 4, tension: 400 }} // animation tha makes the object go back to the initial position after a drag and drop
            >
                <directionalLight
                    ref={directionalLight}
                    position={[1.5, 0.1, 1]}
                    intensity={0.5}
                    castShadow
                />
                <Float rotationIntensity={0.4}>
                    <primitive
                        ref={gearsRef}
                        object={gears.scene}
                        scale={[0.5, 0.5, 0.5]}
                        position-y={0}
                        position-x={-0}
                        rotation-y={0.3}
                        rotation-x={-0.1}
                        receiveShadow
                        onClick={eventHandler}
                        onDragStart={handleDrag}
                        touchAction={true}
                    ></primitive>
                </Float>
            </PresentationControls>
            <ContactShadows
                position-y={-1.1}
                opacity={0.1}
                scale={3}
                blur={8}
            />
        </>
    );
};

export default Model;
