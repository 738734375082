import React, { useEffect } from 'react';
import { useTheme } from '../outils/Hooks.jsx';
import colors from '../outils/Colors.jsx';
import { Link } from 'react-router-dom';

const Card = (props) => {
    const { theme } = useTheme();
    let coverImage = null;
    let button = null;

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show');
                } else {
                    // entry.target.classList.remove('show');
                }
            });
        });
        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));
    });

    const subtitle = props?.subtitle?.find((element) => element.lang === props.lang);
    const title = props?.title?.find((element) => element.lang === props.lang);

    const cardBackground = {
        backgroundColor: `${theme === 'light' ? '#ffffff' : colors.fondFonce}`,
        color: `${theme === 'light' ? colors.text : '#ffffff'}`,
    };

    if (
        title?.text === 'Mon portfolio' ||
        title?.text === 'My portfolio' ||
        title?.text === 'Meu portfolio'
    ) {
        // props.id = null;
        if (theme === 'light') {
            coverImage = 'folio-cover.webp';
        } else {
            coverImage = 'folio-cover-dark.webp';
        }
    }

    const tags = props.tags.map((props, index) => (
        <li
            key={index}
            className={`tagType tagType--${props}`}
        >
            <div className='tagType__tooltip'>{props}</div>
        </li>
    ));

    if (props.lang === 'EN') {
        button = 'For more';
    } else if (props.lang === 'FR') button = 'En savoir plus';
    else button = 'Para saber mais';

    return (
        <article
            className={
                props.anim
                    ? `card card--anim-${props.anim} card--border hidden`
                    : 'card card--border hidden'
            }
            style={cardBackground}
        >
            <Link
                className='card__link'
                to={`${props.id}`}
            >
                <figure className='card__photo'>
                    {title.text && (
                        <img
                            src={`/images/${coverImage ? coverImage : props.coverImage}`}
                            alt={`Projet ${`${title.text}`}`}
                        />
                    )}
                    <ul>
                        <li className={`tagType-project tagType-project--${props.category}`}>
                            {props.category}
                        </li>
                    </ul>
                </figure>
                <div className='card__content'>
                    {title.text && <h3 className='card__content__title'> {`${title?.text}`}</h3>}

                    {/* <p className='card__content__subtitle'>{`${subtitle?.text}`}</p> */}

                    <ul className='card__content__tags'>{tags}</ul>

                    {props.id && <button className='btn btn--white'>{button}</button>}
                </div>
            </Link>
        </article>
    );
};

export default Card;
