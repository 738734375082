import React from 'react';
import { Link } from 'react-router-dom';
import data from '../data/home.json';

const Box = (props) => {
    const content = data.find((content) => content.lang === props.lang);
    if (!content) return null;
    return (
        <div className='box__content'>
            <div className='box__column'>
                <h3>{content.contact?.title}</h3>
            </div>
            <div className='box__column'>
                <p>
                    {content.contact?.text}
                    <span>{content.contact.span}</span>
                </p>
            </div>
            <div className='box__column'>
                <button className='btn btn--home btn-work'>
                    <Link
                        to='mailto:rafaelasodev@gmail.com'
                        className={`${props.theme === 'light' ? `` : `dark`}`}
                    >
                        {content.contact.button}
                    </Link>
                    {/* C'est parti ! */}
                </button>
            </div>
        </div>
    );
};

export default Box;
