import React, { useLayoutEffect, useContext } from 'react';
import Card from '../components/Card.jsx';
import projectsData from '../data/projects.json';
import { useSearchParams, Link } from 'react-router-dom';
import { useTheme } from '../outils/Hooks.jsx';
import { LngContext } from '../outils/Context.jsx';
import colors from '../outils/Colors.jsx';

import AnimatedPage from '../components/AnimatedPage.jsx';

const Projects = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { language } = useContext(LngContext);
    let projectsDisplayed = [];
    const typeFilter = searchParams.get('category');

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const { theme } = useTheme();
    // Filtrer les projets par categorie
    projectsDisplayed = typeFilter
        ? projectsData.filter((data) => data.category === typeFilter)
        : projectsData;

    // Filtrer les projets par languague
    const projectsCards = projectsDisplayed.map((data, index) => {
        if (!data) return;
        return (
            <Card
                id={data.id}
                key={index + 1}
                anim={index + 1}
                title={data.title}
                subtitle={data.subtitle}
                tags={data.tags}
                category={data.category}
                description={data.description}
                coverImage={data.coverImage}
                lang={language}
            />
        );
    });
    return (
        <AnimatedPage>
            <div
                className={`${
                    theme === 'light'
                        ? 'pageTitle light pageTitle__text'
                        : 'pageTitle dark pageTitle__text'
                }`}
            >
                <h1>
                    {language === 'FR'
                        ? 'Mes projets'
                        : language === 'EN'
                        ? 'My projects'
                        : 'Meus projetos'}
                </h1>
            </div>

            <div className='siteWrapper'>
                <section className='container cv'>
                    <ul
                        className={'projectsList__filter'}
                        style={{ color: `${theme === 'light' ? colors.text : 'white'}` }}
                    >
                        <li
                            className={`tagType-project--filter ${
                                typeFilter === 'Perso' ? 'tagType-project--Perso' : ''
                            }`}
                            onClick={() => setSearchParams({ category: 'Perso' })}
                        >
                            Perso
                        </li>
                        <li
                            className={`tagType-project--filter ${
                                typeFilter === 'Training' ? 'tagType-project--Training' : ''
                            }`}
                            onClick={() => setSearchParams({ category: 'Training' })}
                        >
                            Training
                        </li>
                        {typeFilter ? (
                            <li
                                className='btn-clear tag-type-project--none'
                                onClick={() => setSearchParams({})}
                            >
                                Clear filter
                            </li>
                        ) : null}
                    </ul>

                    <div className='cardList'>{projectsCards}</div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default Projects;
