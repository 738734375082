import React from 'react';
import { useRef, useEffect } from 'react';
import {
    Environment,
    useGLTF,
    PresentationControls,
    Float,
    ContactShadows,
    useAnimations,
} from '@react-three/drei';

const Model = () => {
    const directionalLight = useRef();
    const codeRef = useRef();

    const code = useGLTF('/models/code_symbol/code_symbol_compressed_v2.glb');

    const animations = useAnimations(code.animations, code.scene);

    const eventHandler = (e) => {
        e.stopPropagation();
        const action = animations.actions.code_symbol_anim;
        action.play();

        window.setTimeout(() => {
            action.stop();
        }, 2000);
    };

    // //Scroll
    // let scrollY = window.scrollY;

    // //window size
    // const sizes = {
    //     width: window.innerWidth,
    //     height: window.innerHeight,
    // };
    // let currentSection = 0;

    // useEffect(() => {
    //     window.addEventListener('scroll', () => {
    //         scrollY = window.scrollY;
    //         const newSection = Math.round(scrollY / sizes.height);
    //         if (newSection != currentSection) {
    //             const action = animations.actions.code_symbol_anim;
    //             action.play();
    //             window.setTimeout(() => {
    //                 action.stop();
    //             }, 2000);
    //         }
    //     });
    // }, []);

    return (
        <>
            <Environment
                path='/envMap/'
                files='potsdamer_platz_256.hdr'
            />
            <PresentationControls
                global
                rotation={[0.13, 0.1, 0]}
                polar={[-0.4, 0.2]}
                azimuth={[-1, 0.75]}
                config={{ mass: 2, tension: 400 }} // elastic effect
                snap={{ mass: 4, tension: 400 }} // naiamtion that makes the object go back to the initial position after a drag and drop
            >
                <directionalLight
                    ref={directionalLight}
                    position={[-1.5, 0.1, 1]}
                    intensity={0.5}
                    castShadow
                />
                <Float rotationIntensity={1}>
                    <primitive
                        ref={codeRef}
                        object={code.scene}
                        scale={[0.4, 0.4, 0.4]}
                        position-x={0}
                        position-y={0.1}
                        rotation-y={-0.5}
                        rotation-x={-0.3}
                        onClick={eventHandler}
                        receiveShadow
                    ></primitive>
                </Float>
            </PresentationControls>
            <ContactShadows
                position-y={-0.8}
                opacity={0.1}
                scale={3}
                blur={8}
            />
        </>
    );
};

export default Model;
